import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../components/layout'
import BannerLanding from '../components/BannerLanding'

import lpqAccount from '../assets/images/lpq-account.png'
import reactLogo from '../assets/images/react-logo.png'
import wordpressLogo from '../assets/images/wordpress-logo.png'
import scssLogo from '../assets/images/scss-logo.png'
import djangoLogo from '../assets/images/django-logo.png'
import punchhLogo from '../assets/images/punchh-logo.png'
import oloLogo from '../assets/images/olo-logo.png'
import lpqLogo from '../assets/images/lpq-green.png'
import lpqLocations from '../assets/images/lpq-locations.png'

const Landing = props => (
  <Layout>
    <Helmet
      title="Tyler Kemme | Le Pain Quotidien"
      meta={[
        {
          name: 'description',
          content: 'Online Ordering & Loyalty Platform',
        },
        {
          name: 'keywords',
          content:
            'le pain quotidien, wordpress, django, punchh, olo, microservices',
        },
      ]}
    >
      <html lang="en" />
    </Helmet>

    <BannerLanding
      title="Le Pain Quotidien"
      description="Online Ordering & Loyalty Platform"
      to="https://www.lepainquotidien.com/us/en"
      backgroundImage={lpqLogo}
    />

    <div id="main" className="landing-page">
      <section id="one">
        <div className="inner">
          <header className="major">
            <h2>The Project</h2>
          </header>
          <p>
            Le Pain Quotidien website overhaul consisted of a React front-end
            and 10+ microservices to implement online ordering and a loyalty
            program for users. We created 22 country-specific sites in 9
            different languages, but only the US site allowed online ordering
            and loyalty.
          </p>
        </div>
      </section>
      <section id="two" className="spotlights">
        <section>
          <div className="logo-soup">
            <img
              src={reactLogo}
              alt="React logo"
              className="logo-soup-individual"
            />
            <img
              src={wordpressLogo}
              alt="Wordpress logo"
              className="logo-soup-individual"
            />
            <img
              src={scssLogo}
              alt="SCSS logo"
              className="logo-soup-individual"
            />
            <img
              src={djangoLogo}
              alt="Django logo"
              className="logo-soup-individual"
            />
            <img
              src={punchhLogo}
              alt="Punchh logo"
              className="logo-soup-individual"
            />
            <img
              src={oloLogo}
              alt="OLO logo"
              className="logo-soup-individual"
            />
          </div>
          <div className="content">
            <div className="inner">
              <header className="major">
                <h2>Technologies Used</h2>
              </header>
              <p>
                This Headless Wordpress/React site was made using the following
                front-end technologies, developed locally using Docker, and
                hosted on WPEngine
                <br />
                <br />
                <ul>
                  <li>
                    <a
                      href="https://reactjs.org/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      React
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://wordpress.org/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Wordpress
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://sass-lang.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      SCSS
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.djangoproject.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Django
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://punchh.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Punchh
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.olo.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      OLO
                    </a>
                  </li>
                </ul>
              </p>
            </div>
          </div>
        </section>
        <section>
          <div className="image">
            <img src={lpqLocations} alt="Locations Page" />
          </div>
          <div className="content">
            <div className="inner">
              <header className="major">
                <h3>How I contributed - Front-End</h3>
              </header>
              <p>
                Although I assisted with the development of the entire front
                end, I was primarily responsible for implementing the design and
                functionality of the store locator and the account pages. The
                store locator page retrieved data from{' '}
                <a
                  href="https://yext.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Yext's
                </a>{' '}
                API and presented it on a desktop and mobile layout using React
                Google Maps. The account page leveraged Punchh's loyalty
                platform to manage user details, order history, and loyalty
                point accrual.
              </p>
            </div>
          </div>
        </section>
        <section>
          <div className="image">
            <img src={lpqAccount} alt="Django App" />
          </div>
          <div className="content">
            <div className="inner">
              <header className="major">
                <h3>How I contributed - Back-End</h3>
              </header>
              <p>
                Our back-end consisted of more than 10 microservices that
                implemented functionality such as payment processing through
                Mastercard, online ordering through OLO, loyalty through Punchh,
                and data management with Wordpress. Most of our back-end
                services used Django to make API requests to 3rd party services
                and then implemented business logic to return formatted data to
                the front-end. All of our Django apps that contained business
                logic also had extensive unit and integration tests to ensure
                functionality like payment processing was correct before the
                application was even deployed. I personally created the Django
                application that retrieved store information from Yext. I was
                also responsible for some of the functionality and test creation
                on <i>all</i> of the other microservices.
              </p>
            </div>
          </div>
        </section>
      </section>
    </div>
  </Layout>
)

export default Landing
